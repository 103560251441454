import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CommonAppComponent} from '@edward-software/edw-fwk-angular-lib/common-app';
import {EdwHeaderNavbarService, EdwToolsToastService, EdwToolsWorkspaceService} from '@edward-software/edw-fwk-angular-lib/providers';
import {EnvConfig} from '@edward-software/edw-fwk-angular-lib/models';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [MessageService]
})
export class AppComponent extends CommonAppComponent implements OnInit {
    envConfig: EnvConfig;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    constructor(
        route: ActivatedRoute,
        router: Router,
        translate: TranslateService,
        messageService: MessageService,
        workspaceService: EdwToolsWorkspaceService,
        headerNavbarService: EdwHeaderNavbarService,
        toastService: EdwToolsToastService,
        private titleService: Title) {
        super(
            route,
            router,
            translate,
            messageService,
            workspaceService,
            headerNavbarService,
            toastService,
            environment
        );


        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }

    ngOnInit() {
        if (this.envConfig) {
            this.titleService.setTitle(this.envConfig.appTitle);
            this.favIcon.href = this.envConfig.appFavicon;
        } else {
            this.titleService.setTitle('Home');
        }
    }


}
