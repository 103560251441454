import {TranslateLoader} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EdwHttpError, EnvConfig} from '@edward-software/edw-fwk-angular-lib/models';
import {Router} from '@angular/router';
import {EnvConfigService} from '@edward-software/edw-fwk-angular-lib/providers';
import {environment} from '../../../environments/environment';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
    envConfig: EnvConfig;

    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };


    constructor(private http: HttpClient,
                private router: Router) {

        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }

    getTranslation(lang: string): Observable<any> {

        return this.getAllTranslations(lang).pipe(map(
            resp => {
                let obj = {};
                resp.forEach(trad => {
                    if (trad) {
                        obj = Object.assign(obj, trad);
                    }
                });
                return obj;
            },
            (error: EdwHttpError) => {
                this.router.navigate(['error'], {queryParams: {e: 'translations'}});
            }));
    }

    getAllTranslations(lang: string): Observable<any[]> {

        const commonUrl = this.envConfig.apiTranslationUrl + '/translations/file/common/' + lang;
        const homeUrl = this.envConfig.apiTranslationUrl + '/translations/file/home/' + lang;

        let commonRequest = this.http.get(commonUrl, this.options)
            .pipe(
                catchError(e => {
                    return this.http.get(`assets/i18n/common_${lang}.json`);
                }));
        let homeRequest = this.http.get(homeUrl, this.options)
            .pipe(
                catchError(e => {
                    return this.http.get(`assets/i18n/home_${lang}.json`);
                }));

        if (environment.production === false) {
            commonRequest = this.http.get(`./assets/i18n/common_${lang}.json`);
            homeRequest = this.http.get(`./assets/i18n/home_${lang}.json`);
        }

        return forkJoin([commonRequest, homeRequest]);

    }
}
